.o-book-details__container {
  margin-top: 2.5rem;
  margin-left: 7rem;
  margin-right: 7rem;

  display: flex;
  gap: 5rem;
}

.o-book-details__image {
  height: 25rem;
}

.o-book-details__details {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.o-book-details__info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.o-book-details__info-item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.o-book-details__info-item__border {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  margin-left: 1rem;

  border-left: 1px solid;
}
